<template>
  <div id="OnionSharing">
    <div
      class="OnionShar_box"
      :style="{
        background: recordData[0].backImg
          ? 'url(' + recordData[0].backImg + ')'
          : '',
        'background-repeat': 'no-repeat',
        'background-size': '100% 100%',
      }"
    >
      <downBtn
        v-if="!type"
        :topShow="true"
        :isWx="isWx"
        @down="download"
        @wechatShow="wechatShow"
      ></downBtn>

      <div class="OnionShar_bos">
        <div
          class="OnionShar_st"
          v-for="(item, index) in recordData"
          :key="index"
           @click="PlayConversation(index)"
        >
          <div class="dialogue">
            <div class="dialogue_laba">
              <div class="dialogue_b1">
                {{ item.roleName }}
              </div>
              <div class="dialogue_im">
                <img
                  src="../../assets/Vector (4).png"
                  alt=""
                  v-if="item.flng"
                />
                <img
                  src="../../assets/e23aefba-dac9-431c-be65-248e88a06ef5.gif"
                  alt=""
                  v-else
                />
              </div>
            </div>

            <div class="dialogue_b2">
              <div class="dialogue_b3">
                <div
                  class="dialogue_size1"
                  :style="{ color: !item.flng ? '#4E6AFF' : '' }"
                >
                  {{ item.korean }}
                </div>
                <div
                  class="dialogue_size"
                  :style="{ color: !item.flng ? '#4E6AFF' : '' }"
                >
                  {{ item.chinese }}
                </div>
              </div>
              <div class="dialogue_img">
                <img :src="item.roleHead" alt="" />
              </div>
            </div>

            <audio
              id="myaudio"
              class="myaudio"
             
              @ended="handleNext(item, index)"
              :src="
                item.recordAudioNetPath ? item.recordAudioNetPath : item.audio
              "
            ></audio>
          </div>
        </div>
      </div>
    </div>

    <div class="OnionSharing_box" v-if="showging">
      <van-icon
        name="play-circle-o"
        size="100"
        class="OnionSharing_inco"
        color="#ffff"
        @click="btn"
      />
    </div>
  </div>
</template>

<script>
import downBtn from "@/components/downBtn.vue";
import mixin from "@/utils/mixin";
import axios from "axios";
export default {
  mixins: [mixin],
  components: {
    downBtn,
  },
  data() {
    return {
      recordData: [],
      music_path: "",
      inded: "",
      flng: true,
      type: false,
      showging: true,
    };
  },
  created() {
    let url =
      process.env.NODE_ENV == "test"
        ? "http://server-app-test.jixedu.cn/"
        : "https://app-server.jixedu.cn/";
    axios
      .get(url + "drama/getDramaUserRecord", {
        params: {
          dramaChapterId: this.$route.query.dramaChapterId,
          // dramaChapterId: 6,
        },
        headers: {
          token: this.$route.query.token,
          // token:
          //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYXNzd29yZCI6IkUxMEFEQzM5NDlCQTU5QUJCRTU2RTA1N0YyMEY4ODNFIiwiZXhwIjoxNjU2MzM1MTA3LCJhY2NvdW50IjoidTAyNjk2OTcwMTMifQ.abIZrdSPf_f-F34Anho3olkg-alhbgP7fBgSQWfmoE4",
        },
      })
      .then((res) => {
        let data = JSON.parse(res.data.data.recordData);
        data.forEach((item, index) => {
    
          item.flng = true;
        });
        this.recordData = data;
        console.log(this.recordData);
      });
  },
  mounted() {},
  methods: {
    PlayConversation(i) {
      var myaudio = document.querySelectorAll(".myaudio");
      myaudio.forEach((item, index) => {
        if (i === index) {
          myaudio[index].play();
          this.$set(this.recordData[index], "flng", false);
        } else {
          this.$set(this.recordData[index], "flng", true);
          myaudio[index].pause();
          myaudio[index].load();
        }
      });
    },
    handleNext(val, index) {
      val.flng = true;
      var myaudio = document.querySelectorAll(".myaudio");
      if (index + 1 > myaudio.length - 1) return;
      this.recordData[index + 1].flng = false;
      myaudio[index + 1].play();
    },
    btn() {
      this.showging = false;
      var myaudio = document.querySelectorAll(".myaudio");
      this.recordData[0].flng = false;
      myaudio[0].play();
    },
  },
};
</script>

<style scoped lang="scss">
#OnionSharing {
  font-size: 14px;
  position: relative;
  .OnionSharing_box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100vh;
    background: rgba($color: #000000, $alpha: 0.5);
    z-index: 9999;
    clear: both;
    .OnionSharing_inco {
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .OnionShar_box {
    overflow: auto;
    width: 100vw;
    height: 100vh;

    .OnionShar_tab {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 99;
      .OnionShar_title {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        height: 44px;
        background: url("../../assets/Rectangle 2875.png");
        .OnionShar_logo {
          width: 100px;
          height: 32px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .OnionShar_p {
          color: #4e6aff;
          font-size: 14px;
        }
        .OnionShar_buttom {
          width: 54px;
          height: 25px;
          background: #4e6aff;
          border-radius: 12.5px;
          text-align: center;
          line-height: 25px;
          color: #ffffff;
          font-size: 12px;
        }
      }
    }

    .OnionShar_bos {
      margin-top: 44px;
      padding: 20px 10px 0;

      .OnionShar_st {
        &:nth-child(2n) {
          transform: rotateY(-180deg);
          .dialogue_b1 {
            transform: rotateY(-180deg);
          }
          .dialogue_size1 {
            transform: rotateY(-180deg);
          }
          .dialogue_size {
            transform: rotateY(-180deg);
          }
        }
        margin-bottom: 20px;

        .dialogue {
          .dialogue_laba {
            display: flex;
            align-items: center;

            .dialogue_b1 {
              width: 79px;
              height: 23px;
              background: rgba(255, 255, 255, 0.7);
              border-radius: 6px 6px 0px 0px;
              margin-left: 25px;
              text-align: center;
              line-height: 23px;
              font-size: 14px;
              color: #333333;
            }
            .dialogue_im {
              width: 16px;
              height: 14px;
              margin-left: 5px;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          .dialogue_b2 {
            min-height: 135px;
            display: flex;
            position: relative;
            border-radius: 6px 6px 0px 6px;

            background: rgba(255, 255, 255, 0.7);
            .dialogue_b3 {
              margin: 0 0 0 28px;
              padding: 38px 0;
              display: flex;
              flex-direction: column;
              justify-content: center;
              box-sizing: border-box;
              // justify-content: space-between;
              width: 205px;
              // min-height: 135px;
              .dialogue_size {
                font-size: 16px;
                margin: 12px 0 0;
                line-height: 25px;
                word-break: break-all;
              }
              .dialogue_size1 {
                font-size: 18px;
                line-height: 25px;
                word-break: break-all;
              }
              .dialogue_s {
                color: #4e6aff;
              }
            }
            .dialogue_img {
              position: absolute;
              bottom: 0px;
              right: 0px;
              height: 159px;
              width: 115px;
              transform: rotateY(-180deg);

              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }

  .OnionShar_st2 {
  }
}
</style>