var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"OnionSharing"}},[_c('div',{staticClass:"OnionShar_box",style:({
      background: _vm.recordData[0].backImg
        ? 'url(' + _vm.recordData[0].backImg + ')'
        : '',
      'background-repeat': 'no-repeat',
      'background-size': '100% 100%',
    })},[(!_vm.type)?_c('downBtn',{attrs:{"topShow":true,"isWx":_vm.isWx},on:{"down":_vm.download,"wechatShow":_vm.wechatShow}}):_vm._e(),_c('div',{staticClass:"OnionShar_bos"},_vm._l((_vm.recordData),function(item,index){return _c('div',{key:index,staticClass:"OnionShar_st",on:{"click":function($event){return _vm.PlayConversation(index)}}},[_c('div',{staticClass:"dialogue"},[_c('div',{staticClass:"dialogue_laba"},[_c('div',{staticClass:"dialogue_b1"},[_vm._v(" "+_vm._s(item.roleName)+" ")]),_c('div',{staticClass:"dialogue_im"},[(item.flng)?_c('img',{attrs:{"src":require("../../assets/Vector (4).png"),"alt":""}}):_c('img',{attrs:{"src":require("../../assets/e23aefba-dac9-431c-be65-248e88a06ef5.gif"),"alt":""}})])]),_c('div',{staticClass:"dialogue_b2"},[_c('div',{staticClass:"dialogue_b3"},[_c('div',{staticClass:"dialogue_size1",style:({ color: !item.flng ? '#4E6AFF' : '' })},[_vm._v(" "+_vm._s(item.korean)+" ")]),_c('div',{staticClass:"dialogue_size",style:({ color: !item.flng ? '#4E6AFF' : '' })},[_vm._v(" "+_vm._s(item.chinese)+" ")])]),_c('div',{staticClass:"dialogue_img"},[_c('img',{attrs:{"src":item.roleHead,"alt":""}})])]),_c('audio',{staticClass:"myaudio",attrs:{"id":"myaudio","src":item.recordAudioNetPath ? item.recordAudioNetPath : item.audio},on:{"ended":function($event){return _vm.handleNext(item, index)}}})])])}),0)],1),(_vm.showging)?_c('div',{staticClass:"OnionSharing_box"},[_c('van-icon',{staticClass:"OnionSharing_inco",attrs:{"name":"play-circle-o","size":"100","color":"#ffff"},on:{"click":_vm.btn}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }